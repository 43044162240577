define("discourse/plugins/discourse-github-verification/discourse/controllers/preferences-github", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "I18n"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError, _getUrl, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesGithubController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "githubUsername", [_tracking.tracked], function () {
      return this.model.custom_fields.github_verified_username;
    }))();
    #githubUsername = (() => (dt7948.i(this, "githubUsername"), void 0))();
    login() {
      window.location = (0, _getUrl.getAbsoluteURL)("/github-verification/auth-url");
    }
    static #_4 = (() => dt7948.n(this.prototype, "login", [_object.action]))();
    clear() {
      this.dialog.confirm({
        message: _I18n.default.t("discourse_github_verification.clear_confirmation"),
        didConfirm: () => {
          (0, _ajax.ajax)(`/github-verification/clear/${this.model.id}`, {
            method: "DELETE"
          }).then(() => {
            this.githubUsername = null;
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "clear", [_object.action]))();
  }
  _exports.default = PreferencesGithubController;
});